// gatsby-browser.js

// ✅ Stijlen en lettertypen importeren
import "@fontsource/clear-sans";
import "./src/components/main.css";

// ✅ React en Context importeren
import React from "react";
import { LocationProvider } from './src/contexts/LocationContext';

// ✅ Root-element inpakken met de LocationProvider
export const wrapRootElement = ({ element }) => (
  <LocationProvider>
    {element}
  </LocationProvider>
);

